import "./style.scss";
import logo from "../../logo.png";

export const Footer = () => {
  return (
    <div className="footer">
      <a href="https://vk.com/gonagig" target="_blank" rel="noreferrer">
        <img src={logo} alt="GNG" />
      </a>
      <p className="footer_line">Возникли вопросы?</p>
      <p className="footer_line">
        Обратитесь в <a href="#">ПОДДЕРЖКУ</a>
      </p>
    </div>
  );
};
