import React from "react";
import "./style.scss";
import {
  Link,
  useParams,
  useSearchParams,
  redirect,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import API from "../../utils/API";
import { useContext } from "react";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { Footer } from "../Footer";
import { toJS } from "mobx";
import dateFormat, { masks } from "dateformat";

export const ArtistCard = observer((props) => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { store } = useContext(Context);
  const { appStore } = store;
  const { artistData } = appStore;
  const city = searchParams.get("city");
  const tickets = searchParams.get("tickets");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    appStore.showBackArrow();
    appStore.setPage(1);
    appStore.setStartParam(id);
    if (process.env.NODE_ENV !== "development") {
      var WebApp = window.Telegram.WebApp;
      var BackButton = WebApp.BackButton;
      BackButton.show();
    }
    if (!appStore.artists.length) {
      API.get("/artists")
        .then((res) => {
          appStore.setArtists(res.data);
          appStore.setArtistData(id);
        })
        .catch((err) => {
          console.log(err);
          return redirect("/");
        });
    } else {
      appStore.setArtistData(id, city);
    }
  }, []);

  function bookTicket() {
    setIsSuccess(true);
    API.post("/subscribe", {
      tourId: appStore.artistData.tourId,
      chatId:
        process.env.NODE_ENV !== "development"
          ? window.Telegram.WebApp.initDataUnsafe.user.id
          : null,
      artist: appStore.artistData.id,
      city: appStore.city.id,
      ticket: appStore.category.id,
    });
    setTimeout(() => {
      appStore.setPage(4);
      setTimeout(() => {
        navigate("/");
      }, 4000);
    }, 300);
  }
  function drawTickets() {
    if (toJS(artistData)) {
      return toJS(artistData)
        ?.cities?.sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((item) => {
          return (
            <div key={"awx" + item.id} className="tickets_row">
              <div>
                <p className="tickets_date">
                  {dateFormat(item?.date, "dd.mm")}
                </p>
                <p className="tickets_city">{item.city}</p>
              </div>
              <Link
                to={"?city=" + item.id}
                onClick={() => {
                  setTimeout(() => {
                    appStore.setPage(2);
                  }, 300);
                  appStore.setTickets(item.tickets);
                  appStore.setCity(item);
                  appStore.setTourId(item.tourId);
                }}
              >
                <button className="book_btn">ЗАБРОНИРОВАТЬ</button>
              </Link>
            </div>
          );
        });
    }
  }
  function drawCategories() {
    const loadedTickets = toJS(appStore.tickets);
    if (appStore.page === 2) {
      if (loadedTickets) {
        return loadedTickets.map((item) => {
          return (
            <div key={"aw" + item.id} className="tickets_row category_row">
              <div>
                <p className="tickets_city">{item.name}</p>
                {!item.off && <p className="tickets_off">без прохода</p>}
              </div>
              <Link to={"/" + id + "?city=" + city + "&tickets=" + item.id}>
                <button
                  className="book_btn"
                  onClick={() => {
                    setTimeout(() => {
                      appStore.setPage(3);
                    }, 300);
                    appStore.setCategory(item);
                  }}
                >
                  ВЫБРАТЬ
                </button>
              </Link>
            </div>
          );
        });
      }
    }
  }

  return (
    <div className="card_box">
      <div>
        {appStore.page !== 3 && appStore.page !== 4 && (
          <div>
            <img
              className="card_image"
              src={`${artistData?.cardImg}`}
              alt="Artist"
            />
          </div>
        )}
        {appStore.page === 1 && (
          <>
            <h2 className="card_artist_name">{artistData?.artistName}</h2>
            <p className="card_artist_tour_name">{artistData?.tourName}</p>
            <div className="tickets_box">{drawTickets()}</div>
          </>
        )}
        {appStore.page === 2 && (
          <>
            <h2 className="card_artist_date">
              {dateFormat(appStore?.city?.date, "dd.mm")}
            </h2>
            <p className="card_artist_city">{appStore?.city.city}</p>
            <p className="card_tickets_title">КАТЕГОРИИ БИЛЕТОВ</p>
            {drawCategories()}
          </>
        )}
        {appStore.page === 3 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              minHeight: "60vh",
            }}
            className={isSuccess ? "success_anim" : null}
          >
            <p className="card_artist_city">{appStore?.category.name}</p>
            {appStore.category.subtitle ? (
              <p className="tickets_description__subtitle">
                {appStore.category.subtitle}
              </p>
            ) : null}
            <p
              className={
                appStore?.category.description.length < 100
                  ? "tickets_description"
                  : "tickets_description__small"
              }
              dangerouslySetInnerHTML={{
                __html: appStore?.category.description.replaceAll(
                  "\n",
                  "<br />"
                ),
              }}
            ></p>
            <button
              className="book_btn offer_btn"
              style={{ width: "fit-content" }}
              onClick={() => {
                bookTicket();
              }}
            >
              ПОДТВЕРДИТЬ
            </button>
          </div>
        )}
        {appStore.page === 4 && (
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              minHeight: "60vh",
            }}
          >
            <p className="ticket_end_title">
              БИЛЕТ <span>ЗАБРОНИРОВАН</span>
            </p>
            <p className="ticket_end_subtitle">
              ОЖИДАЙТЕ ОПОВЕЩЕНИЕ ДЛЯ ОПЛАТЫ БИЛЕТА!
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
