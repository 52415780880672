import { makeAutoObservable, toJS } from "mobx";

export default class AdminStore {
  openMenu = false;
  backArrow = false;
  artists = [];
  page = 0;
  startParam = null;
  artistData = {};
  loaded = false;
  subscribes = [];
  cities = [];
  city = {};
  tickets = [];
  category = {};
  tourId = "";

  constructor() {
    makeAutoObservable(this);
  }

  setLoaded(value) {
    this.loaded = value;
  }
  toggleMenu() {
    this.openMenu = !this.openMenu;
  }

  setArtists(artists) {
    this.artists = artists;
  }

  hideBackArrow() {
    this.backArrow = false;
  }

  showBackArrow() {
    this.backArrow = true;
  }

  setPage(page) {
    this.page = page;
    if (page === 0) {
      if (process.env.NODE_ENV !== "development") {
        var WebApp = window.Telegram.WebApp;
        var BackButton = WebApp.BackButton;
        BackButton.hide();
      }
    }
  }

  setArtistData(id, city) {
    const artist = toJS(this.artists).find((item) => item.startParam === id);
    this.artistData = artist;
    if (artist) {
      this.cities = artist.cities;
      const resCity = artist.cities.find((i) => i.id === city);
      if (city) {
        this.city = resCity;
      }
    }
  }

  setCities(cities) {
    this.cities = cities;
  }

  setCity(city) {
    this.city = city;
  }

  setStartParam(param) {
    this.startParam = param;
  }

  setTickets(tickets) {
    this.tickets = tickets;
  }

  setCategory(category) {
    this.category = category;
  }
  setTourId(tourId) {
    this.tourId = tourId;
  }
}
